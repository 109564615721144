.main-dashboard {
  padding: 35px 30px;
  //background: #fffcf9;
  border-radius: 30px;
  margin-left: 1rem;
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    padding: 35px 30px;
    margin-left: 1rem;
  }
  .d-header {
    padding-bottom: 30px;
  }
  .d-content {
    display: flex;
    flex: 1;
    .d-div1 {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;
      .d-wmsg {
        margin-bottom: 55px;
      }
    }
    .d-div2 {
      margin-left: 55px;
    }
  }
}
.welcome-msg {
  background-color: #0060f0;
  border-radius: 20px;
  height: 194px;
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    height: 180px;
  }
  position: relative;
  display: flex;
  .w-div-1 {
    flex: 1;
    max-width: 350px;
    padding: 30px 0px 30px 30px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 30px 0px 0px 12px;
    }
    h1 {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 10px;
      color:#FFFFFF;
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        font-size: 26px;
        margin-bottom: 12px;
      }
    }
    p {
      color:#FFFFFF;
      margin-bottom: 18px;
      margin-right: 25px;
      font-weight: 14px;
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        width: 80%;
        margin-bottom: 15px;
      }
    }
    a {
      color: #0060f0;
      font-size: 14px;
      padding: 10px;
      background-color: #ffffff;
      border-radius: 8px;
      //   svg {
      //     margin-left: 20px;
      //     margin-top: 5px;
      //   }
    }
  }
  .welcom-img {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 219.68px;
    height: 168.71px;
    // height: 160px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      right: 0;
      bottom: 0;
      width: 180px;
      height: 140px;
    }
  }
  .w-div-2 {
    width: 20%;
    background-color: #0060f0;
  }
}

.recent-scans {
  background-color: #ffffff;
  padding: 0rem 0.7rem 0rem 1.7rem;
  margin-top: 35px;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}
.activity {
  background-color: white;
  //   min-height: calc(100vh - 300px);
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 35px 12px;
  }
  @media screen and (min-width: 728px) and (max-width: 1024px) {
    padding: 15px;
  }
  .activity-heading {
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 0px;
  }
  .a-p {
    color: #0060f0;
  }
  .graph-div {
    .chart-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      .chart-div {
        position: relative;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 235px;
        height: 235px;
      }
      .chart-center {
        position: absolute;
        top: 27%;
        text-align: center;
        left: 43.5%;
        @media screen and (min-width: 1024px) and (max-width: 1280px) {
          top: 22%;
        }
        span {
          font-size: 25px;
          font-weight: bold;
        }
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
      //   flex-wrap: wrap;
      font-size: 14px;
      list-style-type: unset !important;
      list-style-position: inside;
      // margin-top: 6.5rem;
      padding-left: 0rem;
      margin-bottom: 0rem;
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        width: 90%;
        margin-left: 4% !important;
        font-size: 12px;
        justify-content: space-around;
      }
      li {
        // padding-left: 18px;
        // width: 50%;
        @media screen and (min-width: 1600px) {
          width: auto;
        }
        margin-bottom: 10px;
        :last-child {
          width: 100%;
        }
        .processing {
          padding: 0px 10px;
        }
      }
      ::marker {
        content: "\25CF";
        font-size: 20px;
      }
      .live::marker {
        color: #1a2bcc;
      }
      .processing::marker {
        color: #fe8b38;
      }
      .inactive::marker {
        color: #4fbaf0;
      }
      span {
        font-weight: bold;
        font-size: 16px;
        color: #636973;
        margin-left: 5px;
        @media screen and (min-width: 1024px) and (max-width: 1440px) {
          font-size: 14px;
        }
        @media screen and (min-width: 728px) and (max-width: 1024px) {
          font-size: 12px;
        }

        &:last-child {
          // font-size: 12px;
          font-weight: 400;
          margin-left: 21px;
          // @media screen and (min-width:1280px)  {
          //     font-size: 16px;
          // }
          @media screen and (min-width: 1024px) and (max-width: 1199px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.statistics-div {
  padding: 25px;
  background: #ffffff;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin-top: 2rem;
  .a-p {
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }
  //   .s-div-1 {
  //     height: 120px;
  //     background-color: #fff2e9;
  //     // border-left: 4px solid #0060f0;
  //     border-radius: 20px;
  //     margin-top: 20px;
  //     padding-top: 25px;
  //     padding-left: 25px;
  //     @media screen and (min-width: 1024px) and (max-width: 1280px) {
  //       padding: 20px 12px;
  //       height: auto;
  //     }
  //     .a-p-2 {
  //       color: #8f8f8f;
  //       font-size: 14px;
  //       margin-bottom: 10px;
  //     }
  //     .a-p-3 {
  //       font-size: 36px;
  //       line-height: 36px;
  //       font-weight: bold;
  //       margin: 0px;
  //       @media screen and (min-width: 1024px) and (max-width: 1280px) {
  //         font-size: 24px;
  //         line-height: 24px;
  //       }
  //     }
  //   }
  .s-div-2 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      flex-direction: column;
    }
    .s-div {
      width: 212px;
      height: 147px;
      border-radius: 20px;
      // padding-top: 25px;
      padding-left: 25px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        width: 100%;
        padding: 20px 15px;
        height: auto;
        margin-top: 20px;
      }
      @media screen and (min-width: 1281px) and (max-width: 1400px) {
        width: 180px;
        padding: 20px 15px;
        height: 130px;
        margin-top: 20px;
      }
      .a-p-2 {
        color: #000000;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
        margin-top: 5px;
      }
      .a-p-3 {
        font-size: 30px;
        line-height: 36px;
        font-weight: bold;
        margin: 0px;
        @media screen and (min-width: 1024px) and (max-width: 1280px) {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }
}

.color-1 {
  background-color: #fff2e9;
  color: #0060f0;
}
.color-2 {
  background-color: #ebfff5;
  color: #1daf58;
}
.color-3 {
  background-color: #fff7d9;
  color: #eab90e;
}

.map-div {
  background: #ffffff;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin-top: 2.3rem;
  .map {
    position: relative;
    margin-top: 25px;
    border-radius: 20px;
  }
  iframe {
    height: 191px;
    // width: 50%;
    float: right;
    border-radius: 20px;
  }
  .s-div3 {
    //   height: 98px;
    //   background: linear-gradient(
    //     90deg,
    //     #e7ecf1 0%,
    //     #e7ecf1 50.44%,
    //     rgba(248, 248, 248, 0.3) 101.94%
    //   );
    //   border-left: 4px solid #0060f0;
    //   border-radius: 5px;
    //   position: absolute;
    //   top: 0;
    width: 100%;
    padding: 20px 0px 0px 20px;
    .a-p-2 {
      color: #0060f0;
      line-height: 16px;
      font-size: 14px;
      margin: 0px;
    }
    .a-p-3 {
      font-size: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      color: #0e0e0f;
    }
  }
}

.button-div {
  margin-top: 1rem;
}

@media screen and (min-width: 1024px) and (max-width: 1260px) {
  .col-md-4 {
    width: 31%;
  }
}
