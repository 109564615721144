.alertBox{
    z-index: 100;
    min-width: 400px;
    width: auto;
    padding: 20px;
    border-radius: 08px;
    color: #fff;
    font-size: 20px;
    // display: flex;
    margin: 10px 30px 10px 30px !important; 
    margin-bottom: 40px;
    .alertType{
        font-size: 20px !important;
        font-weight: 800 !important;
        margin-right: 5px;
    }
    .alertMessage{
        font-size: 20px !important;
        // font-weight: 800px !important;
        // margin-right: 10px;
    }
    button{
        float: right;
        background: transparent;
        border: none;
        color: #fff;
        font-weight: 800;
        margin-left: 40px;
        margin-right: 20px;
        cursor: pointer;
    }
}