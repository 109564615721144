@import '../../variables';
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    cursor: default;
}

body{
    background-color: #e7ecf1;
}

.page-wrap{
    max-width: 1920px;
    margin: 0 auto;
}

.col{
    padding-right: 0px;
    padding-left: 0px;
}

a{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

button{
    cursor: pointer;
}
.equalpadding{
    padding: 200px;
    margin-top: 80px;
    @media screen and (max-width: 800px) {
        padding: 10px;

    }
}


/* --------------------------------- navbar --------------------------------- */
.navbar{
    background-color: #f6f5f4;
    // background-image: linear-gradient(to bottom, #F1F1F1, 80%, #F6F6F5);
    padding-bottom: 20px;
    .navbar-brand{
        width: 20%;
        img{
            width: 100%;
        }
    }
    .navrbar-collapse{
        width: 80%;
        .center-nav{
            width: 55%;
        }
        .right-nav-btns{
            width: 25%;
        }
    }
}
/* --------------------------------- navbar --------------------------------- */

.landing-nav-video{
    
    height: 1124px;
    width: 100%;
    background-image: linear-gradient(to bottom , #F1F1F144 , 80% , #0060f096);
    clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 85%);
    margin-left: 0px;
    margin-right: 0px;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        height: 700px;
        clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 88%);
    }
    .landing-video-section{
        .video-content{
            span{
                color: #343434;
                display: block;
                font-size:48px;
                @media screen and (min-width: 1024px) and (max-width: 1280px) {
                    font-size:36px;
                }
                
            }
            .font-bold{
                font-weight:600;
            }
            .slogan{
                display: block;
                font-size: 18px;
                font-weight: 500;
                color: #8F8F8F;
                margin-top: 30px;
                @media screen and (min-width: 1024px) and (max-width: 1280px) {
                    font-size:14px;
                }
            }
            .get-started{
                display: block;
                margin-top: 54px;
                padding: 0px;
                border: none;
                width:199px;
                height:54px;
                background-color:#0060f0;
                border-radius:5px;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
                font-weight:600;
                text-align:center;
                &:hover{
                    border: 1px solid #0060f0;
                    background-color: #fff;
                    color: #0060f0
                }
                a{
                    display: inline-block;
                    width: 100%;
                    height: inherit;
                    line-height: 54px;
                    font-size: 18px;
                    &:hover{
                        color: #0060f0
                    }
                }
            }
        }
        .video-thumbnail{
            margin-top: 50px;
            text-align: center;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
                margin-top: 40px;
            }
            img{
                width: 80%;
                // height: 387px;
                box-shadow: 0px 8px 30px #00000015;
                // @media screen and (min-width: 1024px) and (max-width: 1280px) {
                //     width: 80%;
                //   }
            }
        }
    }
}

.stegvision-special-feature{
    .vertical-align-special{
        align-self: center;
        .stegvision-special{
            border-bottom: 4px solid #0060f0;
            padding-bottom: 40px;
            width: 330px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
                width: 75%;

            }
            .content-left{
                font-size: 48px;
                line-height: 70px;
                @media screen and (min-width: 1024px) and (max-width: 1280px) {
                    font-size: 36px;
                }
            }
            span{
                display: block;
            }
            .font-bold{
                font-weight: 800;
            }
        }
    }
    .stegvision-content-parent{
        display: flex;
        flex-wrap: wrap;
        .stegvision-special-content{
            // margin-right: 140px;
            margin-bottom: 45px;
            display: flex;
            align-items: baseline;
            span{
                display: block;
            }
            .tick{
                padding: 0 0px;
            }
            .content{
                padding-left: 24px;
                width: 315px;
                .heading{
                    font-size: 18px;
                    font-weight: 500;
                }
                .para{
                    font-size: 18px;
                    font-weight: 500;
                    color: #8F8F8F;
                }
            }
            &:nth-last-child(-n+2){
                margin-bottom: 0px;
            }
            &:nth-child(even){
                margin-right: 0px;
            }
        }
    }
}

.quote-section{
    padding: 0px 100px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        padding: 0px 50px;
    }
    @media screen and (min-width: 1440px) and (max-width: 1600px) {
        padding: 0px 75px;
    }
    @media screen and (max-width: 800px) {
        padding: 0px 0px;
    }
    .quote-inner{
        // padding: 110px 250px 110px 128px;
        padding: 100px ;
        background-color: #E7E7E750;
        // opacity: 0.5;
        @media screen and (min-width: 1024px) and (max-width: 1280px) {
            padding: 60px 50px;
        }
        @media screen and (min-width: 1440px) and (max-width: 1600px) {
            padding: 100px 75px;
        }
        @media screen and (max-width: 800px) {
            padding: 0px 0px;
        }
        .heading{
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 40px;
            color: #0060f0;
        }
        .para{
            font-size: 14px;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
        .quote-right{
            p{
                font-size: 32px;
                text-align: center;
                max-width: 400px;
                margin: auto;
            .quoteicon{
                background-image: url('../../assets/images/quote-left.svg');
                width: 50px;
                height: 50px;
                background-size: cover;
                display: inline-block;
            }
                    :first-child{
                        transform: translateY(-20px);
                    }
                    :last-child{
                        transform: scaleX(-1) translateY(70px);
                        
                    }
        }
    }
    }
}

.plan-and-price{
    position: relative;
    padding-top: 200px;
    padding-bottom: 100px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .heading-slogan{
        text-align: center;
        padding-bottom: 45px;
        .heading{
            font-size: 36px;
            font-weight: 500;
        }
        .para{
            font-size: 18px;
            color: #8F8F8F;
        }
    }
    .price-card-wrapper{
        display: flex;
        justify-content: center;
        .price-card{
            position: relative;
            width: 650px;
            padding: 28px 32px 21px 32px;
            margin: 0 25px;
            background: #fff;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.03);
            
            @media screen and (min-width: 1024px) and (max-width: 1280px) {                
                padding: 30px;
        }
            &.premium{
                border: 3px solid #0060f0;
                .popular{
                    width: 100%;
                    height: 50px;
                    background: #0060f0;
                    box-shadow: 0px -1px 16px rgba(0, 0, 0, 0.03);
                    position: absolute;
                    box-sizing: unset;
                    left: -3px;
                    top: -25px;
                    line-height: 50px;
                    text-align: center;
                    border: 3px solid #0060f0;
                    p{
                        color:#fff;
                        font-size: 18px;
                        font-weight: 500;

                    }
                }
            }
            .price-top{
                padding-bottom: 52px;
                margin-bottom: 45px;
                text-align: center;
                border-bottom: 2px solid #F1F1F1;
                .subs-title{
                    color: #0060f0;
                    font-size: 18px;
                    font-weight: 600;
                }
                .price{
                    padding-top: 38px;
                    line-height: 0.7;
                    display: flex;
                    align-content: flex-start;
                    justify-content: center;
                    .currency{
                        margin-right: 4px;
                        font-size: 24px;
                        color: #8F8F8F;
                    }
                    .wo-decimal-price{
                        font-size: 48px;
                        font-weight: 600;
                    }
                    .time-duration{
                        margin-left: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        line-height: 20px;
                        .w-decimal-price{
                            @extend .currency;
                            color:#343434;
                        }
                        .duration{
                            font-size:14px;
                            color: #8F8F8F;
                        }
                    }
                }
            }
            .price-bottom{
                .price-feature-list{
                    li{
                        margin-bottom: 20px;
                        list-style-position: outside;
                        padding-left: 30px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .bottom-bar{
        width: 100%;
        height: 256px;
        background: #0060f0;
        position: absolute;
        bottom: -90px;
        z-index: -1;
    }
}

.partners{
    .partners-content{
        text-align: center;
        padding: 200px 0 0 0;
        @media screen and (min-width: 1024px) and (max-width: 1280px) {
            padding: 110px 0 0 0;

        }
        .partners-brand-white{
            padding: 100px;
            // padding: 45px 395px 25px 395px;
            background-color: #fff;
            .partners-heading{
                color:#0060f0;
                font-size: 24px;
            }
            .partners-brand{
                display: flex;
                overflow-x: auto;
                width: 80%;
                margin: auto;
                // flex-wrap: wrap;
                @media screen and (min-width: 1024px) and (max-width: 1280px) {
                    width: 100%;
                }
            }
            img{
                margin: 20px;
                width: 183px;
                height: 39px;
            }
        }
    }
}

.contact-us{
    
    .contact-us-left{
        height: 100%;
        padding-top: 100px;
        padding-bottom: 68px;
        background-color: #0060f0;
        img{
            position: relative;
            right: 84px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
                right: 40px;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
        }
    }
    .contact-us-right{
        padding: 44px 55px;
        background-color: #fff;
        .contact-us-header{
            .inquiry{
                font-size: 16px;
                color: #8F8F8F;
            }
            .contact-us-text{
                font-size: 36px;
                font-weight: 500;
                color: #0060f0;
                margin-bottom: 40px;
            }
        }
        .contact-form{
            .contact-field-inline{
                display: flex;
                .form-floating{
                    padding-right: 20px;
                    padding-bottom: 20px;
                    width: 50%;
                    &:last-child{
                        padding-right: 0px;
                    }
                }
            }
            .contact-us-textarea{
                textarea{
                    height: 159px;
                }
            }
            .contact-us-btn-parent{
                text-align: end;
                .contact-us-send{
                    margin-top: 40px;
                    padding: 0px;
                    width:199px;
                    height:54px;
                    background-color:#0060f0;
                    border-radius:5px;
                    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
                    font-weight:600;
                    text-align:center;
                    border: 1px solid #0060f0;
                    &:hover{
                        background-color: transparent;
                        color: #0060f0;
                        border: 1px solid #0060f0;
                    }
                }
            }
        }
    }
}

.navbar-area{
    padding-left:0px;
    padding-right:0px;
    .navbar{
        padding: 21px 100px;
        .center-nav{
            display:flex;
            justify-content:space-evenly;
            width:60%;
            margin:auto;
            text-align:center;
            a{
                display:inline-block;
                padding:10px;
                font-size: 16px;
                font-weight: 500;
                color: #343434;
                border-top:4px solid transparent;
                &.active{
                    border-top:4px solid #0060f0;
                }
            }
        }
        .right-nav-btns{
            display:flex;
            align-items:center;
            .nav-signin{
                color: #343434;
                text-decoration:none;
                width:max-content;
                font-weight:500;
                text-align:center;
                line-height:48px;
                height:48px;
                padding-right: 30px;
            }
            .nav-register{
                line-height:48px;
                width:160px;
                height:48px;
                background-color:#0060f0;
                border-radius:5px;
                box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
                color:#fff;
                font-weight:600;
                text-decoration:none;
                text-align:center;
                &:hover{
                    border: 1px solid #0060f0;
                    background: #fff;
                    color: #0060f0;
                }
            }
        }
    }
}

/* -------------------------------------------------------------------------- */
/*                                 Footer Scss                                */
/* -------------------------------------------------------------------------- */
.Footer{
    .bg{
         margin: 0px 100px;
         border-top: 5px solid #0060f0;
         padding: 60px 0px;
         .showVertical{
             img{
                 margin-bottom: 20px;
                 @media screen and (min-width:1024px) and (max-width:1440px){
                    width: 200px;
                 }
             }
            p{
                font-size: 14px;
                color: #343434;
                font-weight: 500;
            }
        }
        .FooterLink{
            color: #343434;
            &:hover:not(:first-child()){
                cursor: pointer;
                color: #0060f0;
            }
        }
        .a{
            color: #343434;
            &:hover:not(:first-child()){
                cursor: pointer;
                color: #0060f0;
            }
        }
        .listing{
            p{
                // color: red;
                &:hover:not(:first-child()){
                    cursor: pointer;
                    color: #0060f0;
                }
            }
        }
         .Footerheading{
             line-height: 49px;
             border-top: 4px solid #0060f0;
             width: min-content;
             color: #8F8F8F;

         }
        }

}
/* -------------------------------------------------------------------------- */
/*                                 Footer Scss End                            */
/* -------------------------------------------------------------------------- */

@media screen and (min-width:1024px) and (max-width:1366px){
    .equalpadding{
        padding:110px;
    }
}