.modal-lg {
  // min-width: 900px;
  // max-width: 900px;
  min-height: 700px;
  .modal-content {
    background: #ffffff;
    border-radius: 30px;
    min-height: 600px;
    .modal-body {
      display: flex;
      padding: 0px;
      .modal-left {
        width: 50%;
        background: #fff9f4;
        padding-left: 50px;
        border-radius: 30px 0px 0px 30px;
        min-height: 656px;
      }
      .modal-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 4rem;
        h3 {
          font-size: 32px;
          line-height: 38px;
          color: #000000;
          font-weight: 800;
        }
        .btn-group {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: 1rem;

          .form-check-label {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            margin-left: 0.5rem;
          }
          .form-check-input:checked {
            background-color: #0060f0;
            border-color: transparent;
            outline: #ffffff;
          }
        }
        .modal-btn-div {
          .common-btn {
            width: 155px;
            height: 45px;
            background: #0060f0;
            box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
            border-radius: 10px;
            margin-top: 1rem;
            &:hover {
              background-color: #fff;
              border: 1px solid #0060f0;
              color: #0060f0;
            }
          }
        }
      }
    }
  }
}
