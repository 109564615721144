* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.dashboard {
  background: #e7ecf1;
  display: flex;
  .div-sidebar {
    background-color: #ffff;
    // padding-right: 0px;
    transition: all 0.3s;
    min-width: 250px;
    border-radius: 20px;
    margin: 1.5rem;
    @media screen and (min-width: 1440px) {
      width: 280px;
      margin: 1.5rem;

    }
  }
  .content {
    flex: 1;
    transition: all 0.3s;
    width: 100%;
  }
  .sidebar-main-div {
    width: 22% !important;
  }
  .close {
    width: 0px;
    overflow: hidden;
  }
  .closeSide {
    width: 50px;
    height: 50px;
    background-color: red;
  }
}
