.profile {
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 20px;
  }
  background-color: white;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  // padding: 25px 35px 35px 35px;
  margin-top: 2rem;
  height: inherit;
  // min-height: 100vh;
  //   overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background: white !important;
  }
  &::-webkit-scrollbar {
    width: 6px;
    margin-right: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background-color: #bababa;
  }
  .profile-h3 {
    padding: 25px 35px 20px 35px;
    // padding-bottom: 20px;
    font-size: 14px;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .p-2 {
    margin-top: 0px;
  }
  .profile-input1 {
    width: 100%;
    .names {
      display: flex;
      .box1,
      .box2 {
        width: 40%;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: #8f8f8f;
        &:last-child {
          color: #343434;
          font-size: 16px;
        }
      }
    }
    // @media screen and (min-width:1024px) and (max-width:1280px),
    // screen and (min-width:1281px) and (max-width:1500px){
    //     width: 65%;
    // }
    margin-top: 35px;
    // width: 50%;
    .form-floating {
      .form-control {
        border: none;
        background: #f4f1ef;
        border-radius: 16px;
      }
      label {
        padding: 1.3rem 0.75rem;
      }
    }
  }
  .profile-input {
    padding: 0px 35px 0px 35px;
    margin-top: 20px;
    margin-bottom: 30px;
    justify-content: space-between;
    .p-i-div {
      width: 50%;
    }
    .form-floating > label {
      padding: 0.5rem 0.5rem;
      font-size: 14px;
    }
  }
  .profile-input3 {
    margin-top: 45px;
    margin-bottom: 45px;
    p {
      margin: revert;
    }
    .form-floating > label {
      padding: 1.3rem 0.75rem;
    }
  }
  .delete-row {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 40px 37px 40px 37px;
    // padding: 40px 2px;
    margin-top: 4rem;
  }
  .profile-delete {
    display: flex;
    justify-content: space-between;

    .dp-1 {
      color: #dd1212;
      font-size: 14px;

      margin-bottom: 0.3rem;
    }
    .dp-2 {
      color: #3c4149;
      font-size: 14px;
      margin-bottom: 0.3rem;
    }
    .p-delete-btn {
      background: #fcecec;
      border-radius: 10px;
      border-color: transparent;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #dd1212;
      height: 54px;
      font-weight: bold;
      width: 186px;
      margin-right: 1rem;
      &:hover {
        background-color: #ff6a6a;
        color: #fff;
      }
    }
  }
  .profile-save {
    text-align: right;
    padding: 0px 50px 40px 35px;
    .common-btn {
      margin-top: 40px;
      background: #0060f0;
      box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
      border-radius: 10px;
      margin-bottom: 2rem;
      float: right;
      width: 186px;
      height: 54px;
      &:hover {
        background-color: #fff;
        border: 1px solid #0060f0;
        color: #0060f0;
      }
    }
  }
}

.form-control {
  font-size: 16px;
  line-height: 19px;
  color: #9b9ea5;
}
