.dashboardPage {
  .heading {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-style: normal;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 0px;
    margin: 0px;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding-left: 12px;
    }
  }
  .table-scroll {
    max-height: 220px;
    overflow-y: scroll;
    padding-right: 0.8rem;
  }
  .dataTables_wrapper {
    overflow: unset;
  }
  .dataTables_filter {
    display: none;
  }
  .recentscantable {
    width: 100% !important;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    thead {
      tr {
        th {
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          position: sticky;
          top: 0;
          background-color: white;
          padding: 18px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          text-align: left;
          &:first-child {
            padding-left: 0px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
              padding-left: 12px;
            }
          }
          &:last-child {
            padding-left: 40px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
              padding-left: 12px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          padding: 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          text-align: left;
          &:first-child {
            padding-left: 0px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
              padding-left: 12px;
            }
          }
          &:last-child {
            padding-left: 40px;
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
              padding-left: 12px;
            }
          }
        }
      }
    }
  }
}
div.dataTables_wrapper div.dataTables_paginate a {
  color: #0060f0;
}

::-webkit-scrollbar {
  width: 7px;
  margin-left: 2rem;
  border-radius: 20px;
  // border: 2px solid #fff;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  margin-bottom: 20px;

  border-top: 3px solid white;
  border-bottom: 3px solid white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e6;
  height: "20px";
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
