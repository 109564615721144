@import "../../variables";
.Create-Project-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 35px 20px;
  //background: #fffcf9;
  border-radius: 30px;
  margin-left: 0.6rem;
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 30px 12px;
  }
  .logo {
    text-align: center;
    margin-bottom: 60px;
  }
  .form {
    width: 100%;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 20px;
    }
    background-color: white;
    box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 25px 35px 35px 35px;
    margin-top: 2rem;
    height: inherit;
    // margin: auto;
    .Heading {
      h3 {
        font-style: normal;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }
      p {
        font-size: 14px;
        line-height: 16px;
        color: #0060f0;
        margin-bottom: 0rem;
      }
    }
    .form-floating {
      margin-top: 30px;
      label {
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        padding-bottom: 0.5rem;
        padding-left: 0.6rem;
      }
      ::placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
      :-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
      ::-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
    }
    .area-floating {
      margin-top: 30px;
      label {
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        padding-bottom: 0.5rem;
        padding-left: 0.6rem;
      }
      textarea {
        background: #f4f1ef;
        border-radius: 16px;
        border: none;
        padding: 1.5rem;
      }
      ::placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
      :-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
      ::-ms-input-placeholder {
        font-size: 16px;
        line-height: 19px;
        color: #9b9ea5;
      }
    }
    .project-details-div {
      margin-top: 3rem;
      h3 {
        font-size: 20px;
        line-height: 23px;
        color: #000000;
      }
      .form-floating {
        margin-top: 10px;
        position: relative;
        .arrow-border {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          color: #ccc7c4;
          font-size: 25px;
          border-radius: 12px;
          margin: 0.5rem 0.4rem 0.4rem 0.4rem;
          position: absolute;
          top: 38%;
          right: 0;
          bottom: 0;
        }
        .form-control {
          background: #f4f1ef;
          border-radius: 16px;
          border: none;
          cursor: pointer;
        }
      }
    }
    .dropzone {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 16px;
      color: #9b9ea5;
      background: #f4f1ef;
      // border: 2px dashed #C9C5C2;
      // border-radius: 16px;
      // padding: 40px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23C9C5C2FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
      border-radius: 16px;
      height: 170px;
      p {
        cursor: pointer;
      }
    }
    .dropzone-text {
      text-align: center;
    }
    .MuiDropzoneArea-text {
      font-size: 16px;
      color: #8f8f8f;
    }
    // .form-floating > .form-control,
    // .form-floating > .form-select {
    //   height: calc(4rem + 12px);
    // }
    // .form-floating > .form-control:focus ~ label,
    // .form-floating > .form-control:not(:placeholder-shown) ~ label,
    // .form-floating > .form-select ~ label {
    //   opacity: 0.65;
    //   transform: scale(0.85) translateY(-1rem) translateX(0.15rem);
    // }
    .check-box {
      margin-top: 35px;
      color: #8f8f8f;
      input {
        margin-right: 25px;
      }
      .text-color {
        color: $background-color;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .account {
      margin-top: 72px;
      color: #8f8f8f;
      a {
        color: $background-color;
        font-size: 16px;
        text-decoration: none;
      }
    }
    .last-div-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      padding-top: 3rem;
      padding-bottom: 1rem;
      .common-btn {
        width: 326px;
        height: 54px;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        background: #0060f0;
        box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
        border-radius: 10px;
        &:hover {
          background-color: #fff;
          border: 1px solid #0060f0;
          color: #0060f0;
        }
      }
      a {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #b9c1cb;
        text-decoration: none;
      }
    }
  }
}
