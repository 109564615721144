.header-project {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  h3 {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    padding-top: 1rem;
  }
}
#processing-table {
  tbody {
    tr {
      td {
        &:first-child {
          width: 140px;
        }
        &:nth-child(2) {
          max-width: 900px !important;
        }
        &:nth-child(4) {
          .btns-div {
            .bottom-buttons {
              .pencil-btun {
                width: 70px;
                height: 36px;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        &:last-child {
          padding-right: 0;
          padding-left: 5px;
        }
      }
    }
  }
}

.processing-project-table {
  background-color: #ffffff;
  margin-top: 2rem;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
}
.status-div {
  font-size: 20px;
  text-transform: capitalize;
}
#newtable {
  width: 100%;
  thead {
    tr {
      th {
        text-align: center;
        color: #8f8f8f !important;
        .sorting_asc::before {
          content: " " !important;
        }
        .sorting_asc::after {
          content: " " !important;
        }
        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
        &:nth-child(2) {
          background-image: url("../../assets/images/Polygon 1.svg");
          background-repeat: no-repeat;
          background-position: right;
          background-position-x: center;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        vertical-align: middle;
        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
table.dataTable > thead .sorting_asc:before {
  content: " " !important;
}
table.dataTable > thead tr .sorting_asc:after {
  content: " " !important;
}
table.dataTable > thead .sorting:before {
  content: " " !important;
}
table.dataTable > thead tr .sorting:after {
  content: " " !important;
}
.dataTables_length {
  display: none;
}
#newtable_filter {
  text-align: initial;
}
