// .setting {
//     padding: 30px;

//     @media screen and (min-width: 1024px) and (max-width: 1280px) {
//         padding: 30px 12px;
//     }
//     .tab-div {
//         padding-top: 40px;
//         display: flex;
//         height: calc(100vh - 145px);
//         .tab-divs {
//             height: 100%;
//         }
//         .tab-side-div {
//             @media screen and (min-width: 1024px) and (max-width: 1280px) {
//                 padding-left: 12px;
//                 padding-top: 20px;
//             }
//             background-color: white;
//             // padding-left: 60px;
//             padding-left: 30px;
//             padding-top: 50px;
//             padding-right: 0px;
//             height: 100%;
//             // width: 386px;
//             p {
//                 color: #0060f0;
//                 margin-left: 30px;
//                 margin-bottom: 35px;
//             }
//             hr {
//                 margin-right: 40px;
//             }
//             .nav {
//                 .nav-item {
//                     margin-top: 5px;
//                     &:hover {
//                         cursor: pointer;
//                     }
//                     .nav-link {
//                         padding: 15px;
//                         display: flex;
//                         justify-content: space-between;
//                         align-items: center;
//                         &:hover {
//                             cursor: pointer;
//                         }
//                         .nav-icons:hover {
//                             cursor: pointer;
//                         }
//                         div:hover {
//                             cursor: pointer;
//                         }
//                         span:hover {
//                             cursor: pointer;
//                         }
//                         svg:hover {
//                             cursor: pointer;
//                         }
//                         .nav-icons:before {
//                             font-size: 20px;
//                             margin-right: 20px;
//                             color: #f1f1f1;
//                         }
//                         .link-inner {
//                             display: flex;
//                             &:hover {
//                                 cursor: pointer;
//                             }
//                         }
//                     }
//                     .nav-link.active {
//                         padding: 15px;
//                         background-color: #e7ecf1;
//                         border-radius: 5px;
//                         svg {
//                             color: #0060f0;
//                         }
//                         .nav-icons:before {
//                             color: #bababa;
//                         }
//                     }
//                     a {
//                         color: black;
//                         img {
//                             margin-right: 20px;
//                         }
//                         svg {
//                             @media screen and (min-width: 1024px) and (max-width: 1280px) {
//                                 margin-right: 10px;
//                             }
//                             float: right;
//                             color: #8f8f8f;
//                             margin-right: 20px;
//                         }
//                     }
//                 }
//             }
//         }
//         .tab-content-div {
//             flex: 1;
//             height: 100%;
//             .tab-content {
//                 height: 100%;
//                 .fade.tab-pane {
//                     height: 100%;
//                 }
//                 .fade.tab-pane.active.show {
//                     height: 100%;
//                 }
//             }
//             // margin-left: 55px;
//         }
//     }
// }

.setting {
  padding: 35px 30px;
  //background: #fffcf9;
  border-radius: 30px;
  margin-left: 0.6rem;
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 30px 12px;
  }
  .heading-right {
    padding-left: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  .nav-link {
    color: #a7acb5;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 18px 12px 0px;
    margin: 0px 1.6rem;
    cursor: pointer;
  }
  .nav-link:first-child {
    padding-left: 0px;
    margin-left: 0rem;
  }
  .nav-link.active {
    color: #0060f0;
    background-color: transparent;
    border-bottom: 3px solid #0060f0;
    .nav-icons:before {
      color: #0060f0;
    }
  }
  .nav-link:hover {
    cursor: pointer;
  }
}
