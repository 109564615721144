.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .header-right-part {
    display: flex;
    gap: 1rem;
    .side-icons {
      width: 38px;
      height: 38px;
      background: white;
      box-shadow: 2px 6px 30px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      margin-top: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      .notification-circle {
        width: 10px;
        height: 10px;
        background: #0060f0;
        border: 2px solid #ffffff;
        border-radius: 50%;
        position: absolute;
        top: 20%;
        right: 25%;
      }
    }
  }
  h3 {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 21px;
    margin-top: 1.5rem;
    @media screen and (min-width: 1024px) and (max-width: 1300px) {
      margin-top: 1.7rem;
    }
  }
  .icons {
    display: flex;
    justify-content: space-between;
    .icon-2-div {
      margin-left: 10px;
      svg {
        transform: rotate(90deg);
      }
    }
  }
}
