.password {
  @media screen and (min-width: 1024px) and (max-width: 1280px) {
    padding: 20px;
  }
  background-color: white;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  margin-top: 2rem;

  // min-height: 100vh;
  //overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background: white !important;
  }
  &::-webkit-scrollbar {
    width: 6px;
    margin-right: 20px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 60px;
    background-color: #bababa;
  }
  .password-h3 {
    // padding-bottom: 20px;
    font-size: 14px;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 25px 35px 20px 35px;
  }
  .password-divs {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0px 35px 0px 35px;
    // @media screen and (min-width: 1024px) and (max-width: 1280px),
    //   screen and (min-width: 1281px) and (max-width: 1600px) {
    //   display: block;
    // }
    .password-input-div {
      //   @media screen and (min-width: 1024px) and (max-width: 1280px),
      //     screen and (min-width: 1281px) and (max-width: 1600px) {
      //     width: 100%;
      //   }
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 42px;
      padding-top: 10px;

      .form-floating {
        position: relative;
        label {
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          padding: 0.5rem 0.5rem;
        }
        .field-eye-icon {
          position: absolute;
          top: 50%;
          right: 23%;
          color: rgba(190, 179, 171, 1);
          display: flex;
          align-items: center;
          svg {
            width: 21px;
            height: 18px;
          }
        }
      }
      .form-floating > .form-control,
      .form-floating > .form-select {
        height: calc(3.5rem + 6px);
        width: 82%;
      }
      .form-floating > .form-control:focus ~ label,
      .form-floating > .form-control:not(:placeholder-shown) ~ label,
      .form-floating > .form-select ~ label {
        opacity: 0.65;
        transform: scale(0.85) translateY(-1rem) translateX(0.15rem);
      }
      .pass {
        float: left;
        font-size: 13px;
        line-height: 15px;
        margin-top: 10px;
        padding-left: 0.5rem;
        color: #000000;
      }
    }
    .pass-list-div {
      @media screen and (min-width: 1024px) and (max-width: 1280px),
        screen and (min-width: 1281px) and (max-width: 1300px) {
        padding-left: 0px;
        width: 50%;
      }

      width: 50%;

      .pw-list {
        background: #faf7f6;
        border-radius: 16px;
        padding: 35px;
        margin-top: 3rem;
        // @media screen and (min-width: 1024px) and (max-width: 1280px),
        //   screen and (min-width: 1281px) and (max-width: 1600px) {
        //   margin-top: 20px;
        // }
        ul {
          padding-left: 0rem;
        }
        p {
          color: #242527;
          font-size: 16px;
          line-height: 19px;
          font-weight: bold;
        }
        li {
          font-size: 14px;
          line-height: 52px;
          text-decoration: none;
          list-style: none;
          color: #000000;
        }
      }
    }
  }
  .n-btn {
    overflow: hidden;
    padding: 0px 35px 3rem 35px;
    .common-btn {
      float: left;
      margin-top: 2.5rem;
      background: #0060f0;
      box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
      border-radius: 10px;
      width: 186px;
      height: 54px;
      &:hover {
        background-color: #fff;
        border: 1px solid #0060f0;
        color: #0060f0;
      }
      @media screen and (min-width: 1024px) and (max-width: 1280px),
        screen and (min-width: 1281px) and (max-width: 1400px) {
        margin-top: 25px;
      }
    }
  }
}
