.activation-btun {
  border: none;
  background-color: #def3db;
  box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #1e8c0e;
  font-weight: 500;
}

.deactivation-btun {
  background-color: #fdefee;
  box-shadow: 2px 7px 60px rgba(249, 139, 46, 0.1);
  border-radius: 6px;
  border: none;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #d8463c;
  font-weight: 500;
}
