* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.side-bar-parent-tabs {
  display: block;
  width: 100%;
  border: none;
  border-radius: unset;
  color: #343434;
  box-shadow: none;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 1rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 0px 0px 5px 0px;
  min-height: 100vh;
  height: 100%;
  border-radius: 20px;
  .sidebar-top {
    text-align: center;
    padding: 0px 30px 0px 30px;

    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 0px 20px;
    }
    .sidebar-border {
      padding: 17px 30px 16px 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .sidebar-logo {
      width: 100%;
    }
  }

  .sidebar-tabs {
    &.middle {
      padding: 10px 30px 50px 30px;
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        padding: 20px;
      }
    }
    &.bottom {
      padding: 33px 0px 0px 38px;
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        padding: 20px;
      }
    }
    position: relative;
    .sidebar-top-tabs {
      &.no-border::after {
        content: none;
      }
      .button-div {
        margin-top: 0;
        padding-bottom: 10px;
       // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      ul.parent-tabs {
        padding: 0px;
        font-weight: 500;
        margin-top: 21px;
        span {
          font-size: 24px;
          padding-right: 20px;
        }
        li {
          .img-arrow {
            float: right;
            padding-top: 0.4rem;
            margin-right: 0rem;
          }
        }
        .dropdown {
          background-color: #fcf8f6;
          border-radius: 20px;
          padding-top: 1rem;
          padding-bottom: 0.5rem;

          .color-green {
            color: #63be4c;
          }
          .color-yellow {
            color: #0060f0;
          }
        }
        li a.active {
          color: #0060f0;
          background-color: #e7ecf1;
          border-radius: 20px;
          .link-icons:before {
            color: #0060f0;
          }
        }
        & > li {
          @extend .side-bar-parent-tabs;
          a {
            color: rgba(129, 135, 144, 1);
            display: block;
            padding: 28px 25px 28px 35px;
            font-weight: 500;
            font-size: 14px;
            padding-left: 20px;
            &:hover,
            &:active {
              text-decoration: none;
            }
            @media screen and (min-width: 1024px) and (max-width: 1280px) {
              padding: 12px;
            }
          }
          img {
            margin-bottom: 0.3rem;
            margin-right: 19px;
            padding-right: 0;
          }
          ul {
            padding-left: 0px;
            list-style: disc;
            & > li {
              @extend .side-bar-parent-tabs;
              width: 100%;
              margin-left: auto;

              & > a {
                color: black;
                display: block;
                padding: 10px 10px 10px 15px;

                @media screen and (min-width: 1024px) and (max-width: 1300px) {
                  font-size: 14px;
                  padding-left: 9px;
                }
                :first-child {
                  width: 100%;
                }
                :last-child {
                  width: max-content;
                }
                .icon-whitecircle {
                  margin-right: 10px;
                  margin-left: 7px;
                  font-size: 10px;
                  &:before {
                    color: #86cd74;
                  }
                  &.dots::before {
                    color: #0060f0;
                  }

                  @media screen and (min-width: 1024px) and (max-width: 1280px) {
                    margin-right: 8px;
                    margin-left: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-footer {
    padding: 0px 24px 20px 24px;
    .sidebar-footer-inner-div {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      span {
        display: flex;
        align-items: center;
        gap: 0.7rem;
        .signout-icon {
          color: #0060f0;
        }
      }
      .help {
        color: #5d6167;
        font-size: 14px;
      }
      .signout {
        color: #5d6167;
        font-size: 14px;
      }
    }
  }
}

.user-short-detail-div {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 25px;
  padding-bottom: 1rem;

  .user-front-div {
    width: 50px;
    height: 50px;
    background: #0060f0;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }
  .user-short-detail {
    text-align: left;
    border-radius: 5px;
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      padding: 12px 12px;
    }
    p {
      margin: 0px;
    }
    .user-name {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: bolder;
      color: black;
    }
    .join-date {
      font-size: 14px;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #7a7f8d;
    }
  }
}
