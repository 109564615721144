.new-project-btn {
  button {
    font-size: 16px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    margin-top: 35px;
    // padding: 15px;
    border: none;
    background-color: #0060f0;
    border-radius: 10px;
    box-shadow: 2px 12px 60px rgba(249, 139, 46, 0.2);
    font-weight: 400;
    border: 1px solid #0060f0;
    color: #fff;
    .icon-plus {
      // display: inline-block;
      // width: 16px;
      margin-right: 10px;
    }
    &:hover {
      background-color: #fff;
      border: 1px solid #0060f0;
      color: #0060f0;
      .icon-plus::before {
        color: #0060f0;
      }
    }
    &:focus {
      outline: none;
    }
  }
}
