$bg-dark-orange:#0060f0;
$bg-light-orange:#0060f0;
$bg-exlight-orange:#E7E7E7;

$body-background:#e7ecf1;
$background-color:#0060f0;
$font-family:'Euclid Circular B', sans-serif;

$font-14: 14px;
$font-18: 18px;
$font-24: 24px;
$font-48: 48px;

$font-dark: #343434;
$font-light: #8F8F8F;
