.status-div {
  font-size: 20px;
  text-transform: capitalize;
}
#newtable {
  width: 100%;
  thead {
    tr {
      th {
        text-align: center;
        color: #8f8f8f !important;
        .sorting_asc::before {
          content: " " !important;
        }
        .sorting_asc::after {
          content: " " !important;
        }
        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
        &:nth-child(2) {
          background-image: url("../../assets/images/Polygon 1.svg");
          background-repeat: no-repeat;
          background-position: right;
          background-position-x: center;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        vertical-align: middle;
        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
table.dataTable > thead .sorting_asc:before {
  content: " " !important;
}
table.dataTable > thead tr .sorting_asc:after {
  content: " " !important;
}
table.dataTable > thead .sorting:before {
  content: " " !important;
}
table.dataTable > thead tr .sorting:after {
  content: " " !important;
}
.dataTables_length {
  display: none;
}
#newtable_filter {
  text-align: initial;
}

.links {
  color: #9fa7b0;
  font-size: 25px;
  text-decoration: none;
}

.links:hover {
  text-decoration: none;
}

.project-table {
  border-radius: 20px;
  background-color: #ffffff;
  margin-top: 2rem;
  padding: 0.5rem 0rem;
  min-height: 85vh;
  overflow-y: scroll;
  box-shadow: 2px 7px 60px rgba(0, 0, 0, 0.04);
}
